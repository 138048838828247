
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexzkvlsSLWjj6__45cqb7WG0pEi69vtLqdp_D6cI0xrI47IMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/index.vue?macro=true";
import { default as indexWa3scroTWUxNBvwP5A7z875bhFV1JjtKkQF_wxY9wNgMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startqFVtRKSeBHUGkJxfnFg7hJrbhYlbIiXFhho3WLmQ4UEMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startscJppYB3zxSq175QzibGZ55vCy_452OVQvS2fHrddslFEMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkouthnwrpcNKSrN5uVrpaz6_45MB4o6RJoeLXO0UmTNX9yHPgMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koNZWhRg2b79hyuAOqsuXwcFXryE3yrc8JzC8SbpMCWLMMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmation8H0pX5esTQm0fitR9wzhvji0pPrAChRwz7C3E9lrq6cMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmation4fFeWQfFYy92ljXN5Y0KikGwD_45wZKp5z_45IeNzm3zaSYMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerzOzlR9tLlHllWPLX8W_450fNjBZ3_RdNeFu8kj8tkZLN8Meta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerdrVXzUZh4DmcGTax7Uyqkiw0NaPZHMd4VWC6tOQZ7zkMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentsP4pXoWVDjUQLevk26ZvjvovD3locrjxzIe_piw9DbAMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengen9b8NDpEoU3XvJah2TjmPVnJ8LPcN8BV6RKAGtbfrakMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentHM_BWYWnKu6qlqnzJRi3_qYduOMhEql4nRjTQGCY94QMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta } from "/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexzkvlsSLWjj6__45cqb7WG0pEi69vtLqdp_D6cI0xrI47IMeta?.name ?? "index",
    path: indexzkvlsSLWjj6__45cqb7WG0pEi69vtLqdp_D6cI0xrI47IMeta?.path ?? "/",
    props: indexzkvlsSLWjj6__45cqb7WG0pEi69vtLqdp_D6cI0xrI47IMeta?.props ?? false,
    meta: indexzkvlsSLWjj6__45cqb7WG0pEi69vtLqdp_D6cI0xrI47IMeta || {},
    alias: indexzkvlsSLWjj6__45cqb7WG0pEi69vtLqdp_D6cI0xrI47IMeta?.alias || [],
    redirect: indexzkvlsSLWjj6__45cqb7WG0pEi69vtLqdp_D6cI0xrI47IMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/index.vue")
  },
  {
    name: indexWa3scroTWUxNBvwP5A7z875bhFV1JjtKkQF_wxY9wNgMeta?.name ?? "lang",
    path: indexWa3scroTWUxNBvwP5A7z875bhFV1JjtKkQF_wxY9wNgMeta?.path ?? "/:lang?",
    props: indexWa3scroTWUxNBvwP5A7z875bhFV1JjtKkQF_wxY9wNgMeta?.props ?? false,
    meta: indexWa3scroTWUxNBvwP5A7z875bhFV1JjtKkQF_wxY9wNgMeta || {},
    alias: indexWa3scroTWUxNBvwP5A7z875bhFV1JjtKkQF_wxY9wNgMeta?.alias || [],
    redirect: indexWa3scroTWUxNBvwP5A7z875bhFV1JjtKkQF_wxY9wNgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startqFVtRKSeBHUGkJxfnFg7hJrbhYlbIiXFhho3WLmQ4UEMeta?.name ?? "lang-voucher-start",
    path: startqFVtRKSeBHUGkJxfnFg7hJrbhYlbIiXFhho3WLmQ4UEMeta?.path ?? "/:lang?/voucher/start",
    props: startqFVtRKSeBHUGkJxfnFg7hJrbhYlbIiXFhho3WLmQ4UEMeta?.props ?? false,
    meta: startqFVtRKSeBHUGkJxfnFg7hJrbhYlbIiXFhho3WLmQ4UEMeta || {},
    alias: startqFVtRKSeBHUGkJxfnFg7hJrbhYlbIiXFhho3WLmQ4UEMeta?.alias || [],
    redirect: startqFVtRKSeBHUGkJxfnFg7hJrbhYlbIiXFhho3WLmQ4UEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startscJppYB3zxSq175QzibGZ55vCy_452OVQvS2fHrddslFEMeta?.name ?? "lang-purchase-start",
    path: startscJppYB3zxSq175QzibGZ55vCy_452OVQvS2fHrddslFEMeta?.path ?? "/:lang?/purchase/start",
    props: startscJppYB3zxSq175QzibGZ55vCy_452OVQvS2fHrddslFEMeta?.props ?? false,
    meta: startscJppYB3zxSq175QzibGZ55vCy_452OVQvS2fHrddslFEMeta || {},
    alias: startscJppYB3zxSq175QzibGZ55vCy_452OVQvS2fHrddslFEMeta?.alias || [],
    redirect: startscJppYB3zxSq175QzibGZ55vCy_452OVQvS2fHrddslFEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkouthnwrpcNKSrN5uVrpaz6_45MB4o6RJoeLXO0UmTNX9yHPgMeta?.name ?? "lang-service-booking-checkout",
    path: checkouthnwrpcNKSrN5uVrpaz6_45MB4o6RJoeLXO0UmTNX9yHPgMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkouthnwrpcNKSrN5uVrpaz6_45MB4o6RJoeLXO0UmTNX9yHPgMeta?.props ?? false,
    meta: checkouthnwrpcNKSrN5uVrpaz6_45MB4o6RJoeLXO0UmTNX9yHPgMeta || {},
    alias: checkouthnwrpcNKSrN5uVrpaz6_45MB4o6RJoeLXO0UmTNX9yHPgMeta?.alias || [],
    redirect: checkouthnwrpcNKSrN5uVrpaz6_45MB4o6RJoeLXO0UmTNX9yHPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koNZWhRg2b79hyuAOqsuXwcFXryE3yrc8JzC8SbpMCWLMMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koNZWhRg2b79hyuAOqsuXwcFXryE3yrc8JzC8SbpMCWLMMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koNZWhRg2b79hyuAOqsuXwcFXryE3yrc8JzC8SbpMCWLMMeta?.props ?? false,
    meta: koNZWhRg2b79hyuAOqsuXwcFXryE3yrc8JzC8SbpMCWLMMeta || {},
    alias: koNZWhRg2b79hyuAOqsuXwcFXryE3yrc8JzC8SbpMCWLMMeta?.alias || [],
    redirect: koNZWhRg2b79hyuAOqsuXwcFXryE3yrc8JzC8SbpMCWLMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmation8H0pX5esTQm0fitR9wzhvji0pPrAChRwz7C3E9lrq6cMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmation8H0pX5esTQm0fitR9wzhvji0pPrAChRwz7C3E9lrq6cMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmation8H0pX5esTQm0fitR9wzhvji0pPrAChRwz7C3E9lrq6cMeta?.props ?? false,
    meta: confirmation8H0pX5esTQm0fitR9wzhvji0pPrAChRwz7C3E9lrq6cMeta || {},
    alias: confirmation8H0pX5esTQm0fitR9wzhvji0pPrAChRwz7C3E9lrq6cMeta?.alias || [],
    redirect: confirmation8H0pX5esTQm0fitR9wzhvji0pPrAChRwz7C3E9lrq6cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmation4fFeWQfFYy92ljXN5Y0KikGwD_45wZKp5z_45IeNzm3zaSYMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmation4fFeWQfFYy92ljXN5Y0KikGwD_45wZKp5z_45IeNzm3zaSYMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmation4fFeWQfFYy92ljXN5Y0KikGwD_45wZKp5z_45IeNzm3zaSYMeta?.props ?? false,
    meta: confirmation4fFeWQfFYy92ljXN5Y0KikGwD_45wZKp5z_45IeNzm3zaSYMeta || {},
    alias: confirmation4fFeWQfFYy92ljXN5Y0KikGwD_45wZKp5z_45IeNzm3zaSYMeta?.alias || [],
    redirect: confirmation4fFeWQfFYy92ljXN5Y0KikGwD_45wZKp5z_45IeNzm3zaSYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerzOzlR9tLlHllWPLX8W_450fNjBZ3_RdNeFu8kj8tkZLN8Meta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerzOzlR9tLlHllWPLX8W_450fNjBZ3_RdNeFu8kj8tkZLN8Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerzOzlR9tLlHllWPLX8W_450fNjBZ3_RdNeFu8kj8tkZLN8Meta?.props ?? false,
    meta: buyerzOzlR9tLlHllWPLX8W_450fNjBZ3_RdNeFu8kj8tkZLN8Meta || {},
    alias: buyerzOzlR9tLlHllWPLX8W_450fNjBZ3_RdNeFu8kj8tkZLN8Meta?.alias || [],
    redirect: buyerzOzlR9tLlHllWPLX8W_450fNjBZ3_RdNeFu8kj8tkZLN8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerdrVXzUZh4DmcGTax7Uyqkiw0NaPZHMd4VWC6tOQZ7zkMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerdrVXzUZh4DmcGTax7Uyqkiw0NaPZHMd4VWC6tOQZ7zkMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerdrVXzUZh4DmcGTax7Uyqkiw0NaPZHMd4VWC6tOQZ7zkMeta?.props ?? false,
    meta: buyerdrVXzUZh4DmcGTax7Uyqkiw0NaPZHMd4VWC6tOQZ7zkMeta || {},
    alias: buyerdrVXzUZh4DmcGTax7Uyqkiw0NaPZHMd4VWC6tOQZ7zkMeta?.alias || [],
    redirect: buyerdrVXzUZh4DmcGTax7Uyqkiw0NaPZHMd4VWC6tOQZ7zkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentsP4pXoWVDjUQLevk26ZvjvovD3locrjxzIe_piw9DbAMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentsP4pXoWVDjUQLevk26ZvjvovD3locrjxzIe_piw9DbAMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentsP4pXoWVDjUQLevk26ZvjvovD3locrjxzIe_piw9DbAMeta?.props ?? false,
    meta: paymentsP4pXoWVDjUQLevk26ZvjvovD3locrjxzIe_piw9DbAMeta || {},
    alias: paymentsP4pXoWVDjUQLevk26ZvjvovD3locrjxzIe_piw9DbAMeta?.alias || [],
    redirect: paymentsP4pXoWVDjUQLevk26ZvjvovD3locrjxzIe_piw9DbAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengen9b8NDpEoU3XvJah2TjmPVnJ8LPcN8BV6RKAGtbfrakMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengen9b8NDpEoU3XvJah2TjmPVnJ8LPcN8BV6RKAGtbfrakMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengen9b8NDpEoU3XvJah2TjmPVnJ8LPcN8BV6RKAGtbfrakMeta?.props ?? false,
    meta: challengen9b8NDpEoU3XvJah2TjmPVnJ8LPcN8BV6RKAGtbfrakMeta || {},
    alias: challengen9b8NDpEoU3XvJah2TjmPVnJ8LPcN8BV6RKAGtbfrakMeta?.alias || [],
    redirect: challengen9b8NDpEoU3XvJah2TjmPVnJ8LPcN8BV6RKAGtbfrakMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentHM_BWYWnKu6qlqnzJRi3_qYduOMhEql4nRjTQGCY94QMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentHM_BWYWnKu6qlqnzJRi3_qYduOMhEql4nRjTQGCY94QMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentHM_BWYWnKu6qlqnzJRi3_qYduOMhEql4nRjTQGCY94QMeta?.props ?? false,
    meta: paymentHM_BWYWnKu6qlqnzJRi3_qYduOMhEql4nRjTQGCY94QMeta || {},
    alias: paymentHM_BWYWnKu6qlqnzJRi3_qYduOMhEql4nRjTQGCY94QMeta?.alias || [],
    redirect: paymentHM_BWYWnKu6qlqnzJRi3_qYduOMhEql4nRjTQGCY94QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/arenys-de-mar/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/barcelona/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/berga/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/caldes-de-montbui/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/calella/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/la-garriga/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/masquefa/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/mataro/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/sant-boi-de-llobregat/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/sant-esteve-sesrovires/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/sant-joan-despi/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/seva/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/sitges/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/barcelona/vallromanes/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 16",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/huelva/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 17",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/huelva/ayamonte/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 18",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/huelva/cartaya/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 19",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/huelva/costa-occidental/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 20",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/huelva/isla-cristina/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.name ?? "Spa by location landing: 21",
    path: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.path ?? "/spa-balneario/huelva/punta-umbria/",
    props: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.props ?? false,
    meta: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta || {},
    alias: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.alias || [],
    redirect: SpaList_46pageU8f6nAoHG0ePs3TJ2A9qnuNZZyl7DyWoJbYJYxWbFEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/spa-berga-resort/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/shama-spa/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/inner-flow/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 16",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/spa-wellness-urh-ciutat-de-mataro/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 17",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 18",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 19",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 20",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 21",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 22",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 23",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 24",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 25",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 26",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 27",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 28",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 29",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 30",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 31",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 32",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 33",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.name ?? "Spa landing: 34",
    path: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/",
    props: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.props ?? false,
    meta: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta || {},
    alias: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.alias || [],
    redirect: SpaDetail_46page00zMjk4sV_45by51XkmuUXx2UQwxiENfsUw3UyToPYXJoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/ritual-exfoliante-de-tierras-lejanas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/capricho-de-la-naturaleza/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/senda-de-los-aromas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/masaje-para-embarazadas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/brisa-del-amanecer/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/sensuality-and-care-inmersion/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/radiance-vitamina-c/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/despertar-de-los-sentidos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/masaje-lomi-lomi/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/cuentame-un-cuento/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/masaje-energetico-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/reflexologia-podal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/luz-atlantica/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/armonia-zonal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/piernas-cansadas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-desayuno-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-viernes-domingo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-desayuno-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-cava-lunes-jueves/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-menu-sofia-bar-tapas-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-desayuno-lunes-jueves/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-cava-viernes-domingo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-desayuno-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-desayuno-viernes-domingo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-terapeutico-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-lunes-jueves/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-desayuno-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-menu-sofia-bar-tapas-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-deportivo-25-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-terapeutico-25-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-deportivo-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-menu-sofia-bar-tapas-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-menu-sofia-bar-tapas-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-deep-tisue-20-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-hydraluronic/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/viaje-a-la-provenza/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-relajante-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-relajante-20-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-menores-4-a-17anos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-60-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-placido-sueno/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/reina-de-egipto-circuito/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/circuito-termal-1h30min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-buffet-restaurante/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/antiestres-45/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/antiestres-25/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia-masaje-doble/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-para-dos-masaje-doble/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-para-dos-masaje-doble/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/pack-armonia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia-masaje-consecutivo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-buffet/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/masaje-karite-55/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-buffet-masaje-doble/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet-masaje-doble/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/date-un-capricho-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/express-massage-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/lulur-ritual-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/choccolat-massage-scrub-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/ritual-candle-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/magic-honey-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/back-soul-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-berga-resort/escapada-parejas-estancia-masaje-spa-piscina-fitness/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-bodycare/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-skincare/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-care-para-parejas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-eternal-love-para-parejas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/spa-privado/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-descontacturante/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-cielo-y-tierra/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-lomi-lomi/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-de-la-sensualidad/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-de-la-alegria/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-antiestres/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/kids-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas-2-ninos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas-nino/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/gourmet-love-para-parejas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-gourmet-para-parejas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico-con-pindas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/glow-flow-spa-ritual/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/deep-tissue-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/digital-wellness-escape/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/aromatherapy-back-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/rejuvenate/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/balmoral-signature-ritual/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/time-ritual-60-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/antistress-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/despertar-ritual-de-relajacion-masaje-y-desayuno/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-gold-edition/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/glow-recovery-facial-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/organic-signature-facial-by-scens-40-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-65-minutos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-gold-edition/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-silver-edition/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-silver-edition/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-25min-parejas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desconecta-para-conectar/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/cuidado-maternal-65min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-25min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-de-25-minutos-infantil/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/escapada-relax-foodie-spa-menu-almuerzo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-40-minutos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-familiar-mascarilla-facial-solidaria/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/silhoutte-perfection-massage-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/despertar-by-serena-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/hot-stone-bliss-90/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/deep-tissue-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/exfoliation-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/couple-cocoon-by-serena-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/digital-wellness-escape/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-circuito-aguas-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/firm-and-tone-sculpting-treatment/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/time-ritual-60-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/melia-exclusive-signature-ritual-by-serena-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/anti-stress-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/antiestres-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/aromatherapy-back-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/sir-victor-exclusive-signature-ritual-by-serena-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/eternal-youth/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-circuito-spa-30-y-masaje-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/exfoliation/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/deep-tissue-massage-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/slow-glow-masaje-y-circuito-spa-con-copa-de-cava-o-te/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/cityscape-spa-masaje-y-gastronomia-en-el-rooftop/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-circuito-spa-30-y-masaje-30/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-y-circuito-spa-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-serena-spa-luxury-signature-ritual-y-spa-privado-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/white-light/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-couple-cocoon-signature-ritual-y-spa-privado-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/beauty-party-spa-privado-90/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/hot-stone-bliss/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/entrada-spa-acceso-general-circuito-de-aguas-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/firm-and-tone-sculpting-treatment/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/digital-wellness/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/melia-exclusive-signature-ritual/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/anti-stress-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/time-ritual-60-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/deep-tissue-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/exfoliation/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/couple-cocoon-by-serena-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/mediterranean-flavours-90/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/signature-journey/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/sweet-orange-body-scrub/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/rejuvenate-60/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/aromaterapia-30/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/digital-wellness-escape-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/serenity-full-body-massage-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/despertar-by-serena-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/aromaterapia-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/time-ritual-60/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/deep-tissue-massage-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/experiencia-gastro-wellness-spa-50-con-opcion-masaje-y-cena-para-2-personas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/acceso-spa-circuito-aguas-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/circuito-ohtels/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-pareja-circuito-ohtels-servicio-de-te-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-pareja-circuito-ohtels-masaje-de-25-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-parejas-circuito-ohtels-masaje-de-15-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/peeling-corporal-de-coco-hidratacion-completa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-esencias-naturales-parcial-25/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-exclusivity-parcial-25/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-de-cerveza-parcial-25/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-de-pindas-herbales-60/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-de-cerveza-completo-55/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-esencias-naturales-completo-55/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-exclusive-spa-privado-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/2x1-spazio-scape-circuito-termal-60min-lunes-a-viernes-11h-14h/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-sunrise-desayuno-buffet-circuito-termal-masaje-aromaterapia-40min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/sri-lanka-30min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/peeling-con-hidratacion/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/aromaterapia-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/age-defense-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-1/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/ocean-miracle-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/sensations-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/higiene-activa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/cacao-tamakuru-90min-spazio-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/infinity-60min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/twin-bombay-80min-spazio-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/secretos-del-nilo-90min-spazio-para-1/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/deep-tissue-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/bambu-detox-80min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-lovers-circuito-termal-1h-masaje-cacao-tamakuru-40min-para-dos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-zen-circuito-termal-60min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-sunrise-desayuno-buffet-circuito-termal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-celebrate-spa-privado-grupos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/holistico-del-tibet-90min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-luxury-circuito-termal-120min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/dia-en-inner-flow/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/bienvenido-a-tu-primera-experiencia-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/spa-masaje-en-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/primera-vez-primer-masaje/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/primera-experiencia-en-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/spa-masaje/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/circuito-spa-adultos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-aromaterapia-25-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-aromaterapia-25-min-doble/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/ritual-kobido-emperatriz/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/ritual-flash/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-shi-zen-relax/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/circuito-spa-ninos-4-15-anos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-115-min-doble/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-140-min-doble/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-115-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/padres-e-hijos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/luz-del-mediterraneo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-mediterranea/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-140-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-junior/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relax-massage-30min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relax-massage-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/love-massage-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-placeres-del-mediterraneo-circuito/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-placeres-del-mediterraneo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-relajante-20-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-menores/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-sunset-en-egipto/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/exfoliante-corporal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/tratamiento-detox/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/reflexologia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/magicglow/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/facial-personalizado/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 257",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/chocolaterapia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 258",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/spa-masaje-25/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 259",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/spa-masaje-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 260",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 261",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/day-pass/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 262",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/day-pass-ninos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 263",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/masaje-deep-tissue-50/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 264",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/masaje-deep-tissue-25/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 265",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-massage-30min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 266",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-massage-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 267",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/love-massage-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 268",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 269",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/pura-elegancia-by-alqvimia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 270",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/terapia-lomi-lomi/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 271",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/masaje-personalizado/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 272",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/majestic-afterwork-masaje-coctel/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 273",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/masaje-facial-kobido/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 274",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/majestic-morning-up-desayuno-zona-de-hidroterapia-masaje/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 275",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/friendly-majestic-duo-moments/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 276",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/momento-quierete/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 277",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/zona-de-hidroterapia-facial-antiedad-cena/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 278",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/diamond-energy-by-natura-bisse/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 279",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/oriental-touch-signature-treatment/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 280",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/flash-facial/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 281",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/c-vitamin-coctel-by-natura-bisse/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 282",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/the-cure-by-natura-bisse/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 283",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/zona-de-hidroterapia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 284",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/momento-romantico/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 285",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/pack-majestic-morning-up/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 286",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/toque-de-calma-by-alqvimia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 287",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/termal-experience/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 288",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/experiencia-relax-termal-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 289",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-120-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 290",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-90-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 291",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-60-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 292",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/serenity-full-body-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 293",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/sport-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 294",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/detox-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 295",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/deep-tissue-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 296",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/digital-wellness-escape/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 297",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 298",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 299",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-bienestar/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 300",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-romance-de-sol-y-luna/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 301",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-placeres-del-mediterraneo-circuito/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 302",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/ritual-de-energia-satsanga-50-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 303",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-craneo-facial/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 304",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/momento-zen/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 305",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/ritual-diva-vila-gale/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 306",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/tratamiento-facial-de-estrella-infantil/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 307",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/manicura-de-diva-infantil/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 308",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/ritual-dia-glamuroso/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 309",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-del-pirata-infantil/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 310",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/cafune-caricias-relajantes-del-pelo-infantil/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 311",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-expres/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 312",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-de-relajacion-total/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 313",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-de-sirena-infantil/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 314",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-vila-gale-30-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 315",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/circuito-spa-3-horas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 316",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/circuito-spa-con-ritual-satsanga-2-personas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 317",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/circuito-spa-ninos-0-12-anos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 318",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-prenatal-30-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 319",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-mediodia-de-lunes-a-viernes-1/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 320",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/kobido-o-lifting-facial-japones/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 321",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/momentos-complices-adulto-nino-a-6-13-anos-1/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 322",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-3/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 323",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 324",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-y-gastro-cena-de-lunes-a-viernes-noches/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 325",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-120-min-viernes-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 326",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-cena-de-lunes-a-viernes/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 327",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/bano-de-hidromasaje-envoltura-de-algas-circuito-termal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 328",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/luxury-private-spa-1/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 329",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/jacuzzi-masaje-en-pareja-circuito-termal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 330",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/ritual-purificante-y-renovador-circuito-termal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 331",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/facial-expres-con-acido-hialuronico-i-q10-circuito-termal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 332",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/escapada-termal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 333",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-gastro-fin-de-semana/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 334",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-abyhanga/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 335",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-gastro-fines-de-semana-y-festivos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 336",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-en-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 337",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 338",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-viernes-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 339",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 340",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-y-gastro-almuerzo-de-lunes-a-viernes-mediodia/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 341",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-breakfast-lunes-a-viernes-festivos-no-incluidos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 342",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-breakfast-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 343",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/luxury-private-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 344",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 345",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/drenaje-linfatico-de-50-circuito-termal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 346",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/momentos-complices-adulto-nino-a-6-13-anos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 347",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-subacuatico/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 348",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/relax-massage-30min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 349",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/relax-massage-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 350",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/love-massage-50min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 351",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 352",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/aquae-private-experience-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 353",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/romantic-private-experience-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 354",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/termal-private-experience-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 355",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/termal-experience/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 356",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/masaje-general/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 357",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-masaje-60-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 358",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-masaje-30-min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 359",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-masaje-relax-30/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 360",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-deep-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 361",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-masaje-relax-60/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 362",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 363",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/ritual-oxigen/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 364",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/ritual-go-sun-passion-fruit/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 365",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/masaje-relax-health-30/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 366",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/masaje-relax-health-60/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 367",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-privado/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 368",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/sesion-cromoterapia-masaje/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 369",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/deep-massage/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 370",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/sesion-reiki-masaje/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 371",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/rituales-diosas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 372",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-relajante-mundo-de-los-aromas/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 373",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-relajante/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 374",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/ritual-a-cuatro-manos/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 375",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/ritual-atardecer-en-donana/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 376",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/tratamiento-exotico-atlantico/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 377",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/ritual-sensual-care-inmersion/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 378",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/ritual-power-to-myself/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 379",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/tratamiento-especial-after-sun/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 380",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-en-cabina-duo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 381",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-profundo/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 382",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-luz-serena/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 383",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-armonia-zonal/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 384",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/date-un-capricho-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 385",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/express-massage-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 386",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/lulur-ritual-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 387",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/choccolat-massage-scrub-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 388",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/ritual-candle-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 389",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/magic-honey-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 390",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/acceso-al-spa-para-2/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 391",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/back-soul-acceso-al-spa/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 392",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 393",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 394",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-completo-55-min-sala-comun-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 395",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 396",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-completo-55-min-sala-comun-1-persona/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 397",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 398",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 399",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-localizado-25min-sala-comun-1-persona/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 400",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 401",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 402",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/masaje-exclusivity-de-cerveza-25min/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 403",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 404",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 405",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.name ?? "Service landing: 406",
    path: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-vip-en-sala-privada-1-persona/",
    props: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.props ?? false,
    meta: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta || {},
    alias: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.alias || [],
    redirect: ServiceDetail_46pageKoRmpFkHR8xnmzuZKxfD1yxwIyPolOIwaHdbpf8RWnYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1d2a13f7-1451-4c9d-801c-f78305a832ad/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]